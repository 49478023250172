.vcb-snippet.vcb-custom .vcb-section__title {
  font-size: 1.3em;
  line-height: inherit; }

.vcb-snippet.vcb-custom .vcb-fill-parent {
  position: absolute;
  width: 100%;
  height: 100%; }

.vcb-snippet.vcb-custom .vcb-pos-a {
  position: absolute; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-xs {
      position: absolute; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-sm {
      position: absolute; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-sm-and-down {
      position: absolute; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-sm-and-up {
      position: absolute; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-md {
      position: absolute; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-md-and-down {
      position: absolute; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-md-and-up {
      position: absolute; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-pos-a-lg {
      position: absolute; } }
  .vcb-snippet.vcb-custom .vcb-pos-a-top {
    position: absolute;
    top: 0; }
    @media only screen and (max-width: 767px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-xs {
        position: absolute;
        top: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-sm {
        position: absolute;
        top: 0; } }
    @media only screen and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-sm-and-down {
        position: absolute;
        top: 0; } }
    @media only screen and (min-width: 768px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-sm-and-up {
        position: absolute;
        top: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-md {
        position: absolute;
        top: 0; } }
    @media only screen and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-md-and-down {
        position: absolute;
        top: 0; } }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-md-and-up {
        position: absolute;
        top: 0; } }
    @media only screen and (min-width: 1200px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-top-lg {
        position: absolute;
        top: 0; } }
  .vcb-snippet.vcb-custom .vcb-pos-a-right {
    position: absolute;
    right: 0; }
    @media only screen and (max-width: 767px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-xs {
        position: absolute;
        right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-sm {
        position: absolute;
        right: 0; } }
    @media only screen and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-sm-and-down {
        position: absolute;
        right: 0; } }
    @media only screen and (min-width: 768px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-sm-and-up {
        position: absolute;
        right: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-md {
        position: absolute;
        right: 0; } }
    @media only screen and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-md-and-down {
        position: absolute;
        right: 0; } }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-md-and-up {
        position: absolute;
        right: 0; } }
    @media only screen and (min-width: 1200px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-right-lg {
        position: absolute;
        right: 0; } }
  .vcb-snippet.vcb-custom .vcb-pos-a-bottom {
    position: absolute;
    bottom: 0; }
    @media only screen and (max-width: 767px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-xs {
        position: absolute;
        bottom: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-sm {
        position: absolute;
        bottom: 0; } }
    @media only screen and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-sm-and-down {
        position: absolute;
        bottom: 0; } }
    @media only screen and (min-width: 768px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-sm-and-up {
        position: absolute;
        bottom: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-md {
        position: absolute;
        bottom: 0; } }
    @media only screen and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-md-and-down {
        position: absolute;
        bottom: 0; } }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-md-and-up {
        position: absolute;
        bottom: 0; } }
    @media only screen and (min-width: 1200px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-bottom-lg {
        position: absolute;
        bottom: 0; } }
  .vcb-snippet.vcb-custom .vcb-pos-a-left {
    position: absolute;
    left: 0; }
    @media only screen and (max-width: 767px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-xs {
        position: absolute;
        left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-sm {
        position: absolute;
        left: 0; } }
    @media only screen and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-sm-and-down {
        position: absolute;
        left: 0; } }
    @media only screen and (min-width: 768px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-sm-and-up {
        position: absolute;
        left: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-md {
        position: absolute;
        left: 0; } }
    @media only screen and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-md-and-down {
        position: absolute;
        left: 0; } }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-md-and-up {
        position: absolute;
        left: 0; } }
    @media only screen and (min-width: 1200px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-left-lg {
        position: absolute;
        left: 0; } }
  .vcb-snippet.vcb-custom .vcb-pos-a-v-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (max-width: 767px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-xs {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-sm {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-sm-and-down {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (min-width: 768px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-sm-and-up {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-md {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-md-and-down {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-md-and-up {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (min-width: 1200px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-v-center-lg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
  .vcb-snippet.vcb-custom .vcb-pos-a-h-center {
    position: absolute;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto; }
    @media only screen and (max-width: 767px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-xs {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-sm {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }
    @media only screen and (max-width: 991px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-sm-and-down {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }
    @media only screen and (min-width: 768px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-sm-and-up {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-md {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }
    @media only screen and (max-width: 1199px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-md-and-down {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-md-and-up {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }
    @media only screen and (min-width: 1200px) {
      .vcb-snippet.vcb-custom .vcb-pos-a-h-center-lg {
        position: absolute;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto; } }

.vcb-snippet.vcb-custom .vcb-pos-r {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-xs {
      position: relative; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-sm {
      position: relative; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-sm-and-down {
      position: relative; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-sm-and-up {
      position: relative; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-md {
      position: relative; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-md-and-down {
      position: relative; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-md-and-up {
      position: relative; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-pos-r-lg {
      position: relative; } }

.vcb-snippet.vcb-custom .vcb-flex-align-self-center {
  align-self: center; }

.vcb-snippet.vcb-custom .vcb-flex-direction-column {
  flex-direction: column; }

.vcb-snippet.vcb-custom .vcb-flex-direction-row {
  flex-direction: row; }

.vcb-snippet.vcb-custom .vcb-block {
  padding: 15px; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-block-xs {
      padding: 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-block-sm {
      padding: 15px; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-block-sm-and-down {
      padding: 15px; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-block-sm-and-up {
      padding: 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-block-md {
      padding: 15px; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-block-md-and-down {
      padding: 15px; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-block-md-and-up {
      padding: 15px; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-block-lg {
      padding: 15px; } }

.vcb-snippet.vcb-custom .vcb-color-inherit {
  color: inherit; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-xs {
      color: inherit; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-sm {
      color: inherit; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-sm-and-down {
      color: inherit; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-sm-and-up {
      color: inherit; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-md {
      color: inherit; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-md-and-down {
      color: inherit; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-md-and-up {
      color: inherit; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-color-inherit-lg {
      color: inherit; } }

.vcb-snippet.vcb-custom .vcb-extra-vertical-padding {
  padding-top: 40px;
  padding-bottom: 40px; }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-extra-vertical-padding {
      padding-top: 80px;
      padding-bottom: 80px; } }
  .vcb-snippet.vcb-custom .vcb-extra-vertical-padding-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-extra-vertical-padding-top {
        padding-top: 80px; } }
  .vcb-snippet.vcb-custom .vcb-extra-vertical-padding-bottom {
    padding-bottom: 40px; }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom .vcb-extra-vertical-padding-bottom {
        padding-bottom: 80px; } }

.vcb-snippet.vcb-custom .vcb-margin-top-25 {
  margin-top: 25px; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-xs {
      margin-top: 25px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-sm {
      margin-top: 25px; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-sm-and-down {
      margin-top: 25px; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-sm-and-up {
      margin-top: 25px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-md {
      margin-top: 25px; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-md-and-down {
      margin-top: 25px; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-md-and-up {
      margin-top: 25px; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-25-lg {
      margin-top: 25px; } }

.vcb-snippet.vcb-custom .vcb-margin-bottom-15 {
  margin-bottom: 15px; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-xs {
      margin-bottom: 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-sm {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-sm-and-down {
      margin-bottom: 15px; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-sm-and-up {
      margin-bottom: 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-md {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-md-and-down {
      margin-bottom: 15px; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-md-and-up {
      margin-bottom: 15px; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-15-lg {
      margin-bottom: 15px; } }

.vcb-snippet.vcb-custom .vcb-margin-top-0 {
  margin-top: 0; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-xs {
      margin-top: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-sm {
      margin-top: 0; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-sm-and-down {
      margin-top: 0; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-sm-and-up {
      margin-top: 0; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-md {
      margin-top: 0; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-md-and-down {
      margin-top: 0; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-md-and-up {
      margin-top: 0; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-margin-top-0-lg {
      margin-top: 0; } }

.vcb-snippet.vcb-custom .vcb-margin-bottom-0 {
  margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-sm {
      margin-bottom: 0; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-sm-and-down {
      margin-bottom: 0; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-sm-and-up {
      margin-bottom: 0; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-md {
      margin-bottom: 0; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-md-and-down {
      margin-bottom: 0; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-md-and-up {
      margin-bottom: 0; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-margin-bottom-0-lg {
      margin-bottom: 0; } }

.vcb-snippet.vcb-custom .vcb-padding-bottom-15 {
  padding-bottom: 15px; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-xs {
      padding-bottom: 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-sm {
      padding-bottom: 15px; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-sm-and-down {
      padding-bottom: 15px; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-sm-and-up {
      padding-bottom: 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-md {
      padding-bottom: 15px; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-md-and-down {
      padding-bottom: 15px; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-md-and-up {
      padding-bottom: 15px; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-padding-bottom-15-lg {
      padding-bottom: 15px; } }

.vcb-snippet.vcb-custom .vcb-border-1 {
  border-width: 1px;
  border-style: solid; }

.vcb-snippet.vcb-custom .vcb-border-5 {
  border-width: 5px;
  border-style: solid; }

.vcb-snippet.vcb-custom .vcb-border-10 {
  border-width: 10px;
  border-style: solid; }

.vcb-snippet.vcb-custom .vcb-border-pointer-top {
  height: 30px;
  width: 60px;
  border-top-width: 30px;
  border-top-style: solid;
  border-top-color: inherit;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-xs {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-sm {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-sm-and-down {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-sm-and-up {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-md {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-md-and-down {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-md-and-up {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-top-lg {
      height: 30px;
      width: 60px;
      border-top-width: 30px;
      border-top-style: solid;
      border-top-color: inherit;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent; } }

.vcb-snippet.vcb-custom .vcb-border-pointer-right {
  height: 60px;
  width: 30px;
  border-top: 30px solid transparent;
  border-right-width: 30px;
  border-right-style: solid;
  border-right-color: inherit;
  border-bottom: 30px solid transparent; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-xs {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-sm {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-sm-and-down {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-sm-and-up {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-md {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-md-and-down {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-md-and-up {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-right-lg {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-right-width: 30px;
      border-right-style: solid;
      border-right-color: inherit;
      border-bottom: 30px solid transparent; } }

.vcb-snippet.vcb-custom .vcb-border-pointer-bottom {
  height: 30px;
  width: 60px;
  border-right: 30px solid transparent;
  border-bottom-width: 30px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  border-left: 30px solid transparent; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-xs {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-sm {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-sm-and-down {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-sm-and-up {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-md {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-md-and-down {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-md-and-up {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-bottom-lg {
      height: 30px;
      width: 60px;
      border-right: 30px solid transparent;
      border-bottom-width: 30px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      border-left: 30px solid transparent; } }

.vcb-snippet.vcb-custom .vcb-border-pointer-left {
  height: 60px;
  width: 30px;
  border-top: 30px solid transparent;
  border-left-width: 30px;
  border-left-style: solid;
  border-left-color: inherit;
  border-bottom: 30px solid transparent; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-xs {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-sm {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-sm-and-down {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-sm-and-up {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-md {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-md-and-down {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-md-and-up {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-border-pointer-left-lg {
      height: 60px;
      width: 30px;
      border-top: 30px solid transparent;
      border-left-width: 30px;
      border-left-style: solid;
      border-left-color: inherit;
      border-bottom: 30px solid transparent; } }

.vcb-snippet.vcb-custom .vcb-border-radius-rounded {
  border-radius: 20px;
  overflow: hidden; }

.vcb-snippet.vcb-custom .vcb-border-radius-circle {
  border-radius: 10000px;
  overflow: hidden; }

.vcb-snippet.vcb-custom .vcb-ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.vcb-snippet.vcb-custom .vcb-below-cb-icons-height {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(57.5% - 35px);
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-xs {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-sm {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-sm-and-down {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-sm-and-up {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-md {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-md-and-down {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-md-and-up {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-height-lg {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(57.5% - 35px);
      overflow: hidden; } }

.vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: calc(57.5% - 35px);
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-xs {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-sm {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-sm-and-down {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-sm-and-up {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-md {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-md-and-down {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-md-and-up {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-below-cb-icons-max-height-lg {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: calc(57.5% - 35px);
      overflow: hidden; } }

.vcb-snippet.vcb-custom .vcb-gradient {
  background: transparent;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  color: white; }

.vcb-snippet.vcb-custom .vcb-gradient-parent--hover:hover .vcb-gradient {
  background: transparent;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.4) 100%); }

.vcb-snippet.vcb-custom .vcb-column-count-2 {
  column-count: 2; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-xs {
      column-count: 2; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-sm {
      column-count: 2; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-sm-and-down {
      column-count: 2; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-sm-and-up {
      column-count: 2; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-md {
      column-count: 2; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-md-and-down {
      column-count: 2; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-md-and-up {
      column-count: 2; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-column-count-2-lg {
      column-count: 2; } }

.vcb-snippet.vcb-custom .vcb-column-count-3 {
  column-count: 3; }
  @media only screen and (max-width: 767px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-xs {
      column-count: 3; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-sm {
      column-count: 3; } }
  @media only screen and (max-width: 991px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-sm-and-down {
      column-count: 3; } }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-sm-and-up {
      column-count: 3; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-md {
      column-count: 3; } }
  @media only screen and (max-width: 1199px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-md-and-down {
      column-count: 3; } }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-md-and-up {
      column-count: 3; } }
  @media only screen and (min-width: 1200px) {
    .vcb-snippet.vcb-custom .vcb-column-count-3-lg {
      column-count: 3; } }

.vcb-snippet.vcb-custom .vcb-admin-only {
  display: none;
  pointer-events: none; }
  body.vcb-active:not(.vcb-disabled) .vcb-snippet.vcb-custom .vcb-admin-only {
    display: block; }
  *:not(.ui-draggable):not(.ui-dragbox-outlined) .vcb-snippet.vcb-custom .vcb-admin-only.vcb-visible-on-edit {
    display: none;
    pointer-events: none; }
  body.vcb-active:not(.vcb-disabled) .ui-draggable.ui-dragbox-outlined .vcb-snippet.vcb-custom .vcb-admin-only.vcb-visible-on-edit {
    display: block; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form hr,
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form label,
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form input:not(.btn):not(.captcha-input),
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form textarea,
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form select {
  display: block;
  width: 100%; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form textarea {
  min-height: 100px;
  resize: vertical; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form input::-webkit-input-placeholder,
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form textarea::-webkit-input-placeholder {
  opacity: 0.25; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form input:-moz-placeholder,
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form textarea:-moz-placeholder {
  opacity: 0.25; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form input::-moz-placeholder,
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form textarea::-moz-placeholder {
  opacity: 0.25; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form input:-ms-input-placeholder,
.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-contact-form textarea:-ms-input-placeholder {
  opacity: 0.25; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-icon-wrapper {
  float: left;
  width: 40px;
  font-size: 28px; }

.vcb-snippet.vcb-custom.vcb-contact-snippet .vcb-details-wrapper {
  float: left;
  width: calc(100% - 40px); }

.vcb-snippet.vcb-custom.vcb-contact-snippet .social__list .social__item {
  display: inline-block;
  font-size: 25px; }
  .vcb-snippet.vcb-custom.vcb-contact-snippet .social__list .social__item:not(:last-child) {
    margin-right: 10px; }

.vcb-snippet.vcb-custom.contact-form-01 .vcb-contact-banner {
  border-width: 5px;
  border-style: solid; }

.vcb-snippet.vcb-custom.contact-form-01 .vcb-contact-banner-divider {
  width: 100px;
  border-bottom-width: 5px;
  border-bottom-style: solid; }

@media only screen and (max-width: 991px) {
  .vcb-snippet.vcb-custom.snippet-full-width-01 .vcb-textbox {
    background-color: transparent !important; } }

.vcb-snippet.vcb-custom.snippet-full-width-03 .vcb-parallax {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  transform: translatey(-50%); }
  .vcb-snippet.vcb-custom.snippet-full-width-03 .vcb-parallax-wrapper {
    height: 100%;
    width: 100%;
    clip: rect(0, auto, auto, 0);
    overflow: hidden; }
  .vcb-snippet.vcb-custom.snippet-full-width-03 .vcb-parallax.vcb-parallax-textbox {
    width: 70vw;
    transform: translatey(0); }
  .vcb-snippet.vcb-custom.snippet-full-width-03 .vcb-parallax.vcb-parallax-embed {
    pointer-events: none; }

.vcb-snippet.vcb-custom.snippet-grid-01 .vcb-section__title {
  padding-right: 75px;
  line-height: 30px; }
  .vcb-snippet.vcb-custom.snippet-grid-01 .vcb-section__title .icon-wrapper {
    width: 60px;
    height: 100%; }

.vcb-snippet.vcb-custom.snippet-grid-02 .vcb-section__title {
  padding-right: 75px;
  line-height: 30px; }
  .vcb-snippet.vcb-custom.snippet-grid-02 .vcb-section__title .icon-wrapper {
    left: auto;
    width: 60px;
    height: 100%; }

.vcb-snippet.vcb-custom.snippet-grid-03 .vcb-section__title {
  max-width: calc(100% - 15px); }

.vcb-snippet.vcb-custom.snippet-grid-04 .vcb-section a:not(:hover) .vcb-section__title:not([data-se*="bgc("]) {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff; }

.vcb-snippet.vcb-custom.snippet-grid-05 .vcb-section__title {
  height: 60px;
  padding-right: 60px; }
  .vcb-snippet.vcb-custom.snippet-grid-05 .vcb-section__title .icon-wrapper {
    left: auto;
    width: 60px;
    height: 100%; }

.vcb-snippet.vcb-custom.snippet-grid-07 .snippet-title {
  z-index: 2;
  margin-top: 0;
  margin-bottom: -40px;
  font-size: 2em; }
  @media only screen and (min-width: 768px) {
    .vcb-snippet.vcb-custom.snippet-grid-07 .snippet-title {
      font-size: 4em; } }

@media only screen and (max-width: 767px) {
  .vcb-snippet.vcb-custom.snippet-grid-08 .vcb-section--promo:not(.vcb-section--promo5) {
    padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .vcb-snippet.vcb-custom.snippet-grid-08 .vcb-section--promo1 {
    padding-top: 7%; } }

@media only screen and (min-width: 768px) {
  .vcb-snippet.vcb-custom.snippet-grid-08 .vcb-section--promo4 {
    padding-top: 7%; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vcb-snippet.vcb-custom.snippet-grid-08 .vcb-section--promo5 {
    margin-top: -5%; } }

@media only screen and (min-width: 992px) {
  .vcb-snippet.vcb-custom.snippet-grid-08 .vcb-section--promo5 {
    padding-top: 4%; } }

.vcb-snippet.vcb-custom.snippet-grid-10 .vcb-promo-wrapper {
  overflow: hidden; }
  @media only screen and (min-width: 992px) {
    .vcb-snippet.vcb-custom.snippet-grid-10 .vcb-promo-wrapper:not(:hover) .vcb-section__textbox {
      height: 50px; } }
  .vcb-snippet.vcb-custom.snippet-grid-10 .vcb-promo-wrapper .vcb-section__textbox {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    transition: height 0.2s linear; }
  .vcb-snippet.vcb-custom.snippet-grid-10 .vcb-promo-wrapper .vcb-section__title {
    font-size: 20px;
    line-height: 1; }

.vcb-snippet.vcb-custom.snippet-grid-11 .vcb-promo-wrapper {
  overflow: hidden; }
  .vcb-snippet.vcb-custom.snippet-grid-11 .vcb-promo-wrapper:hover .vcb-section__textbox {
    height: 100%; }
  .vcb-snippet.vcb-custom.snippet-grid-11 .vcb-promo-wrapper .vcb-section__textbox {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    transition: height 0.2s linear; }
    @media only screen and (min-width: 992px) {
      .vcb-snippet.vcb-custom.snippet-grid-11 .vcb-promo-wrapper .vcb-section__textbox {
        height: 50px; } }
  .vcb-snippet.vcb-custom.snippet-grid-11 .vcb-promo-wrapper .vcb-section__title {
    font-size: 20px;
    line-height: 1; }

body.vcb-active:not(.vcb-disabled) .ui-draggable.ui-dragbox-outlined .vcb-snippet.vcb-custom.snippet-grid-11 .vcb-section__textbox {
  position: relative !important;
  height: auto !important; }

.vcb-snippet.vcb-custom.snippet-text-04 .vcb-promo-section > * {
  align-self: center; }

.vcb-snippet.vcb-custom.snippet-text-04 .vcb-promo-section__text {
  max-width: calc(100% - (40px + 15px)); }

.vcb-snippet.vcb-custom.snippet-text-04 i.icon {
  margin-right: 15px;
  font-size: 40px; }

.vcb-snippet.vcb-custom.snippet-text-05 .image-wrapper {
  position: relative;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3); }
  .vcb-snippet.vcb-custom.snippet-text-05 .image-wrapper.image-wrapper-back {
    top: 20px;
    transform: rotate(-5deg); }
  .vcb-snippet.vcb-custom.snippet-text-05 .image-wrapper.image-wrapper-front {
    top: -20px;
    transform: rotate(5deg); }
